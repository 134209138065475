@keyframes spin {
  from {
    transform: rotateY(0);
  }
  to {
    transform: rotateY(-360deg);
  }
}
.wrapper {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 20px;
}
.hoop-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  min-height: 20vh;
  background-color: transparent;
  perspective: 13em;
  perspective-origin: 50% 1em;
  position: relative;
  transition: transform 2s;
}

@media only screen and (min-width: 1600px) {
  .hoop-wrapper {
    perspective: 17em;
  }
}
@media only screen and (min-width: 2200px) {
  .hoop-wrapper {
    perspective: 19em;
  }
}
@media only screen and (max-width: 510px) {
  .hoop-wrapper {
    perspective: 10em;
  }
}

#hoop {
  position: relative;
  width: 4em;
  height: 4em;
  animation: spin 7s infinite linear;
  transform-style: preserve-3d;
}

.carousel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 3s;

  &-letter {
    color: #7fcfc7;
    font: 2rem sans-serif;
  }
}

.hoop .one {
  transform: translateZ(2.5em);
}
.hoop .two {
  transform: rotateY(30deg) translateZ(2.5em);
}
.hoop .three {
  transform: rotateY(60deg) translateZ(2.5em);
}
.hoop .four {
  transform: rotateY(90deg) translateZ(2.5em);
}
.hoop .five {
  transform: rotateY(120deg) translateZ(2.5em);
}
.hoop .six {
  transform: rotateY(150deg) translateZ(2.5em);
}
.hoop .seven {
  transform: rotateY(180deg) translateZ(2.5em);
}
.hoop .eight {
  transform: rotateY(210deg) translateZ(2.5em);
}
.hoop .nine {
  transform: rotateY(-120deg) translateZ(2.5em);
}
.hoop .ten {
  transform: rotateY(-90deg) translateZ(2.5em);
}
.hoop .eleven {
  transform: rotateY(300deg) translateZ(2.5em);
}
.hoop .twelve {
  transform: rotateY(330deg) translateZ(2.5em);
}
