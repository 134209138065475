/* =================================== */
/*  5. Elements
/* =================================== */

/*=== 5.1 Featured Box ===*/
.featured-box {
  box-sizing: border-box;
  position: relative;
  h3,
  h4 {
    font-size: 1.25rem;
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 500;
  }
  &:not(.style-5) .featured-box-icon {
    display: inline-block;
    font-size: 48px;
    min-width: 55px;
    min-height: 55px;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0.8rem;
    color: #4c4d4d;
    border-radius: 0;
  }
  &.style-1,
  &.style-2,
  &.style-3 {
    padding-left: 50px;
    padding-top: 8px;
  }
  &.style-1 .featured-box-icon,
  &.style-2 .featured-box-icon,
  &.style-3 .featured-box-icon {
    position: absolute;
    top: 0;
    left: 0;
    margin-bottom: 0;
    font-size: 30px;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    text-align: center;
  }
  &.style-2 p {
    margin-left: -50px;
  }
  &.style-3 {
    padding-left: 90px;
    padding-top: 0px;
    .featured-box-icon {
      width: 70px;
      height: 70px;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
  }
  &.style-4 {
    text-align: center;
    .featured-box-icon {
      margin: 0 auto 24px;
      margin: 0 auto 1.5rem;
      width: 120px;
      height: 120px;
      text-align: center;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      justify-content: center;
      @include box-shadow(0px 0px 50px rgba(0, 0, 0, 0.03));
    }
  }

  &.style-5 {
    text-align: center;
    background: #fff;
    border: 1px solid #f0f2f3;
    @include box-shadow(0px 2px 5px rgba(0, 0, 0, 0.05));
    @include transition(all 0.3s ease-in-out);
    &:hover {
      border: 1px solid #ebeded;
      @include box-shadow(0px 5px 1.5rem rgba(0, 0, 0, 0.15));
    }
    h3 {
      background: #f1f5f6;
      font-size: 16px;
      padding: 8px 0;
      margin-bottom: 0px;
    }
    .featured-box-icon {
      font-size: 50px;
      margin: 44px 0px;
    }
  }
}

@mixin featured-box-reverse {
  text-align: right;
  &.style-1,
  &.style-2 {
    padding-right: 50px;
    padding-left: 0px;
    .featured-box-icon {
      left: auto;
      right: 0px;
    }
  }
  &.style-2 p {
    margin-right: -50px;
    margin-left: 0;
  }
  &.style-3 {
    padding-left: 0;
    padding-right: 90px;
    .featured-box-icon {
      left: auto;
      right: 0px;
    }
  }
}

.featured-box.featured-box-reverse,
html[dir="rtl"] .featured-box:not(.style-4) {
  @include featured-box-reverse;
}

@include media-breakpoint-up(sm) {
  .featured-box.featured-box-reverse-sm {
    @include featured-box-reverse;
  }
}

@include media-breakpoint-up(md) {
  .featured-box.featured-box-reverse-md {
    @include featured-box-reverse;
  }
}

@include media-breakpoint-up(lg) {
  .featured-box.featured-box-reverse-lg {
    @include featured-box-reverse;
  }
}
@include media-breakpoint-up(xl) {
  .featured-box.featured-box-reverse-xl {
    @include featured-box-reverse;
  }
}

/* Video Play button */

.btn-video-play {
  width: 66px;
  height: 66px;
  line-height: 66px;
  text-align: center;
  display: inline-block;
  font-size: 16px;
  border-radius: 50%;
  background: #fff;
  @include box-shadow(0px 0px 50px -35px rgba(0, 0, 0, 0.4));
  @include transition(all 0.8s ease-in-out);
  &:hover {
    @include box-shadow(0px 0px 0px 8px rgba(250, 250, 250, 0.2));
  }
}

/* Testimonial */
.testimonial {
  background: #fff;
  border: 1px solid #f1f5f6;
  @include box-shadow(0px 5px 10px rgba(0, 0, 0, 0.05));
  @include transition(all 0.3s ease-in-out);
}

/*=== 5.2 Team ===*/

.team {
  text-align: center;
  .team-img {
    position: relative;
    overflow: hidden;
    display: -ms-flexbox;
    display: flex;
  }
  .team-overlay {
    text-align: center;
    display: -ms-flexbox !important;
    display: flex !important;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    opacity: 0;
    @include transition(all 0.3s ease-in-out);
  }
  &:hover .team-overlay {
    opacity: 1;
  }
  .team-overlay-details {
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
  }
  .team-img img {
    @include transition(all 0.3s ease-in-out);
  }
  &:hover .team-img img {
    @include scale(1.04);
  }
  .team-details {
    padding: 1.5rem 0;
  }
}

/*=== 5.3 Accordion ===*/
.accordion {
  .accordion-item {
    border: none;
    margin-bottom: 10px;
    margin-bottom: 0.6rem;
    background-color: transparent;
  }
  .accordion-header .accordion-button {
    box-shadow: none;
    padding: 1.25rem 1.25rem 1.25rem 2.25rem;
    border-radius: 4px;
    position: relative;
    font-size: 1.125rem;
    font-size: 18px;
    font-weight: 600;
  }
  &:not(.accordion-flush) .accordion-header .accordion-button {
    background-color: $primary-color;
    color: #212529;
    &.collapsed {
      background-color: rgba(0, 0, 0, 0.06);
      color: #4c4d4d;
    }
  }
  .accordion-header .accordion-button {
    &:after {
      position: absolute;
      content: " ";
      left: 20px;
      top: calc(50% + 2px);
      width: 9px;
      height: 9px;
      border-color: #ccc;
      border-top: 2px solid;
      border-right: 2px solid;
      -webkit-transform: translate(-50%, -50%) rotate(-45deg);
      transform: translate(-50%, -50%) rotate(-45deg);
      @include transition(all 0.2s ease);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      background-image: none;
    }
    &.collapsed:after {
      top: calc(50% - 2px);
      -webkit-transform: translate(-50%, -50%) rotate(135deg);
      transform: translate(-50%, -50%) rotate(135deg);
    }
  }
  .accordion-body {
    line-height: 1.8;
    padding: 1rem 0 0.4rem 2.25rem;
  }
  &.arrow-right {
    .accordion-header .accordion-button {
      padding-left: 1.25rem;
      &:after {
        right: 15px;
        left: auto;
      }
    }
    .accordion-body {
      padding-left: 1.25rem;
    }
  }

  &.accordion-flush {
    .accordion-item {
      margin: 0;
    }
    .accordion-header .accordion-button {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 0px;
      background: transparent;
      color: #212529;
    }
    .accordion-item:first-of-type .accordion-header .accordion-button {
      border-top: 0px;
    }
    .accordion-header .accordion-button {
      &:after {
        left: 18px;
      }
      &.collapsed {
        color: rgba(0, 0, 0, 0.4);
      }
    }
    .accordion-body {
      padding: 0rem 0 1rem 2.25rem;
    }
    &.arrow-right .accordion-header .accordion-button {
      padding-left: 0;
      &:after {
        right: 0px;
        left: auto;
      }
    }
  }
}

html[dir="rtl"] .accordion {
  .accordion-header .accordion-button {
    padding: 1rem 2.25rem 1rem 1.25rem;
    &:after {
      left: auto;
      right: 12px;
    }
  }
  .accordion-body {
    padding-right: 2.25rem;
    padding-left: 0;
  }
  &.arrow-right {
    .accordion-header .accordion-button {
      padding-right: 1.25rem;
      padding-left: 2.25rem;
      &::after {
        left: 20px;
        right: auto;
      }
    }
    .accordion-body {
      padding-right: 1.25rem;
      padding-left: 0;
    }
  }
  &.accordion-flush.arrow-right .accordion-header .accordion-button {
    padding-right: 0;
    padding-left: 2.25rem;
    &::after {
      left: 6px;
      right: auto;
    }
  }
}

/* 5.4 Nav */

.nav .nav-item .nav-link {
  color: rgba(0, 0, 0, 0.4);
}
.nav.nav-light .nav-item .nav-link {
  color: #ddd;
}
.nav:not(.nav-pills) .nav-item .nav-link.active,
.nav:not(.nav-pills) .nav-item .nav-link:hover {
  color: #212529;
}

.nav-pills .nav-link:not(.active):hover {
  color: #000;
}
.nav-pills .nav-link.active,
.nav-pills.nav-light .nav-link.active,
.nav-pills .show > .nav-link {
  color: #212529;
}

.nav.nav-separator .nav-item .nav-link {
  position: relative;
}
.nav.nav-separator .nav-item + .nav-item .nav-link:after {
  height: 14px;
  width: 1px;
  content: " ";
  background-color: rgba(0, 0, 0, 0.2);
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  @include translateY(-7px);
}
html[dir="rtl"] .nav.nav-separator .nav-item + .nav-item .nav-link:after {
  right: 0;
  left: auto;
}
.nav.nav-separator.nav-separator-light .nav-item + .nav-item .nav-link:after {
  background-color: rgba(250, 250, 250, 0.2);
}

.nav.nav-sm .nav-item .nav-link {
  font-size: 14px;
}

/*=== 5.5 Tabs ===*/

.nav-tabs {
  .nav-item .nav-link {
    border: 0;
    background: transparent;
    position: relative;
    border-radius: 0;
    padding: 0.6rem 1rem;
    white-space: nowrap !important;
    &.active {
      &:after {
        height: 3px;
        width: 100%;
        content: " ";
        background-color: $primary-color;
        display: block;
        position: absolute;
        bottom: -2px;
        left: 0;
        @include translateY(-3px);
      }
      color: #0c2f55;
    }
    &:not(.active):hover {
      color: #000;
    }
  }
  &.flex-column {
    border-right: 1px solid #d7dee3;
    border-bottom: 0px;
    padding: 1.5rem 0;
    .nav-item {
      .nav-link {
        border: 1px solid #d7dee3;
        border-right: 0px;
        background-color: #f6f7f8;
        font-size: 14px;
        padding: 0.75rem 1rem;
      }
      &:first-of-type .nav-link {
        border-top-left-radius: 4px;
      }
      &:last-of-type .nav-link {
        border-bottom-left-radius: 4px;
      }
      .nav-link.active {
        &:after {
          height: 100%;
          width: 2px;
          background: #fff;
          right: -2px;
          left: auto;
        }
        background-color: transparent;
      }
    }
  }
}

html[dir="rtl"] .nav-tabs {
  &.flex-column {
    border-left: 1px solid #d7dee3;
    border-right: 0px;
    .nav-item {
      .nav-link {
        border: 1px solid #d7dee3;
        border-left: 0px;
      }
      &:first-of-type .nav-link {
        border-top-right-radius: 4px;
        border-top-left-radius: 0px;
      }
      &:last-of-type .nav-link {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 0px;
      }
      .nav-link.active {
        &:after {
          left: -1px;
          right: auto;
        }
      }
    }
  }
}

.nav-tabs:not(.flex-column) {
  .nav-item {
    margin-bottom: 0px;
  }
  flex-wrap: nowrap;
  overflow: hidden;
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-overflow-scrolling: touch;
}

@include media-breakpoint-up(lg) {
  .search-input-2 {
    .form-control {
      border-radius: 0px;
    }
    .custom-select:not(.custom-select-sm) {
      border-radius: 0px;
      height: calc(3.05rem);
    }
    .btn {
      border-radius: 0px;
    }
    .form-group {
      &:first-child {
        .form-control,
        .custom-select {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }
      &:last-child .btn {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
    .form-control:focus,
    .custom-select:focus {
      box-shadow: none;
      -webkit-box-shadow: none;
    }
    .form-group .form-control,
    .custom-select {
      border-left: none;
      border-top: none;
      border-bottom: none;
    }
  }

  /* CSS hack for Chrome */
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    .search-input-2 {
      .custom-select:not(.custom-select-sm) {
        height: calc(3rem);
      }
      .btn {
        line-height: inherit;
      }
    }
  }

  /*  CSS hack for Firfox */
  @-moz-document url-prefix() {
    .search-input-2 {
      .custom-select:not(.custom-select-sm) {
        height: calc(3.05rem);
      }
      .btn {
        line-height: 1.4;
      }
    }
  }
}

/*=== 5.6 Hero Background ===*/
.hero-wrap {
  position: relative;
  overflow: hidden;
  .hero-mask,
  .hero-bg,
  .hero-bg-slideshow {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .hero-mask {
    z-index: 1;
  }
  .hero-content {
    position: relative;
    z-index: 2;
  }
  .hero-particles {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 3;
  }
  .hero-bg-slideshow {
    z-index: 0;
  }
  .hero-bg {
    z-index: 0;
    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    transition: background-image 300ms ease-in 200ms;
    &.hero-bg-scroll {
      background-attachment: scroll;
    }
  }
  .hero-bg-slideshow {
    .hero-bg {
      background-attachment: inherit;
    }
    &.owl-carousel {
      .owl-stage-outer,
      .owl-stage,
      .owl-item {
        height: 100%;
      }
    }
  }
}

/*=== 5.7 Slick Slider ===*/
.slick-prev:before,
.slick-next:before,
[dir="rtl"] .slick-prev:before,
[dir="rtl"] .slick-next:before {
  content: none;
}
.slick-prev,
.slick-next,
.slick-prev:focus,
.slick-next:focus {
  z-index: 1;
  background-color: rgba(250, 250, 250, 0.8);
  color: #000;
  width: 44px;
  height: 44px;
  line-height: 44px;
  font-size: 16px;
  text-align: center;
  margin: 0;
  top: calc(50% - 44px);
  border-radius: 50%;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.slick-slider:hover .slick-prev,
.slick-slider:hover .slick-next {
  opacity: 1;
}

.slick-prev:hover,
.slick-next:hover {
  background-color: rgba(250, 250, 250, 1);
  color: #000;
}

.slick-prev {
  left: 5px;
}
[dir="rtl"] .slick-prev {
  right: auto;
  left: 5px;
}
.slick-next {
  right: 5px;
}
[dir="rtl"] .slick-next {
  right: 5px;
  left: auto;
}
.slick-dots {
  position: initial;
  margin-top: 10px;
  li {
    margin: 0;
    width: auto;
    height: auto;
    button {
      position: relative;
      width: 20px;
      height: 20px;
      margin: 5px 3px;
      border: 1px solid transparent;
      display: block;
      -webkit-backface-visibility: visible;
      transition: all 0.2s ease;
      border-radius: 30px;
      &:before {
        transition: all 0.5s ease;
        width: 10px;
        height: 10px;
        content: "";
        left: 50%;
        top: 50%;
        position: absolute;
        border-radius: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        margin-left: -5px;
        margin-top: -5px;
        opacity: 1 !important;
      }
    }
    &.slick-active button {
      border-color: $primary-color;
      transition: all 0.5s ease;
    }
    &.slick-active button:before,
    button:hover:before {
      background-color: $primary-color;
      transition: all 0.5s ease;
    }
  }
}

/*=== 5.8 Brands Grid ===*/

.brands-grid {
  overflow: hidden;
  > .row > * {
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
  }
  &.separator-border > .row > * {
    &:after,
    &:before {
      content: "";
      position: absolute;
    }
    &:after {
      width: 100%;
      height: 0;
      top: auto;
      left: 0;
      bottom: -1px;
      border-bottom: 1px dotted rgba(0, 0, 0, 0.25);
    }
    &:before {
      height: 100%;
      top: 0;
      left: -1px;
      border-left: 1px dotted rgba(0, 0, 0, 0.15);
    }
  }
  &.separator-border.separator-border-light > .row > * {
    &:after {
      border-bottom: 1px dotted rgba(250, 250, 250, 0.15);
    }
    &:before {
      border-left: 1px dotted rgba(250, 250, 250, 0.15);
    }
  }
}

/*=== 5.9 Portfolio ===*/

.portfolio {
  .portfolio-box {
    position: relative;
    .portfolio-img {
      position: relative;
      overflow: hidden;
    }
    .portfolio-overlay {
      text-align: center;
      display: -ms-flexbox !important;
      display: flex !important;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.7);
      opacity: 0;
      @include transition(all 0.3s ease-in-out);
    }
    &:hover .portfolio-overlay {
      opacity: 1;
    }
    .portfolio-img img {
      @include transition(all 0.3s ease-in-out);
    }

    &:hover .portfolio-img img {
      -webkit-filter: blur(2px);
      filter: blur(2px);
      @include scale(1.03);
    }
    .portfolio-overlay-details {
      width: 100%;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
  .portfolio-details {
    padding: 1.5rem 0;
    text-align: center;
  }
}

.ajax-container {
  margin: 20px auto;
}

.project-details-modal .btn-close {
  float: right;
}

html[dir="rtl"] {
  .project-details-modal .btn-close {
    float: left;
  }
  .mfp-content {
    text-align: right;
  }
  .mfp-close {
    left: 0px;
    right: auto;
  }
}

/*=== 5.10 List Style ===*/

.list-style-1 > li {
  position: relative;
  list-style-type: none;
  line-height: 24px;
  &:after {
    content: " ";
    position: absolute;
    top: 12px;
    left: -15px;
    border-color: #000;
    border-top: 1px solid;
    border-right: 1px solid;
    width: 6px;
    height: 6px;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
  }
}
html[dir="rtl"] .list-style-1 > li {
  &:after {
    right: -15px;
    left: auto;
    border-color: #000;
    border-top: 0px;
    border-right: 0px;
    border-left: 1px solid;
    border-bottom: 1px solid;
    width: 6px;
    height: 6px;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

.list-style-2 {
  padding: 0;
}

.list-style-2 > li {
  list-style-type: none;
  border-bottom: 1px solid #eaeaea;
  padding-top: 12px;
  padding-bottom: 12px;
}
.list-style-2.list-style-light > li {
  border-bottom: 1px solid rgba(250, 250, 250, 0.12);
}

// MAIN PORTFOLIO HOVER STYLES

.view .mask,
.view .content {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
}
.view img {
  display: block;
  position: relative;
}
.view h2 {
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  position: relative;
  font-size: 1.6rem;
  padding: 4rem 0 0.5rem 0rem;
  // background: rgba(0, 0, 0, 0.8);
  margin: 1.5rem 1rem 0 1rem;
}
.view p {
  font-weight: 300;
  font-size: 1.3rem;
  position: relative;
  color: #fff;
  padding: 10px 20px 20px;
  text-align: center;
}
.view a.info {
  display: inline-block;
  text-decoration: none;
  padding: 7px 14px;
  background: #000;
  color: #fff;
  text-transform: uppercase;
  box-shadow: 0 0 1px #000;
}
.view a.info:hover {
  box-shadow: 0 0 5px #000;
}

// HOVER EFFECTS to Portfolio

.view-first img {
  transition: all 0.2s linear;
}
.view-first .mask {
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.75);
  transition: all 0.4s ease-in-out;
}
.view-first h2 {
  border-bottom: 2px solid rgba(217, 217, 217, 0.3);
  transform: translateY(-100px);
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
.view-first p {
  transform: translateY(100px);
  opacity: 0;
  transition: all 0.2s linear;
}
.view-first a.info {
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.view-first:hover img {
  transform: scale(1.1);
}
.view-first:hover .mask {
  opacity: 1;
}
.view-first:hover h2,
.view-first:hover p,
.view-first:hover a.info {
  opacity: 1;
  transform: translateY(0px);
}
.view-first:hover p {
  transition-delay: 0.1s;
}
.view-first:hover a.info {
  transition-delay: 0.2s;
}

// CONTACT ME LINK

.link-intro {
  color: #000;
  letter-spacing: 1px;
  .bi {
    font-weight: 400;
    transition: all 0.2s;
  }
  .bi:nth-child(2) {
    transition-delay: 0.05s;
  }
  .bi:nth-child(3) {
    transition-delay: 0.1s;
  }
  .bi:nth-child(4) {
    transition-delay: 0.15s;
  }
  .bi:nth-child(5) {
    transition-delay: 0.2s;
  }
  .bi:nth-child(6) {
    transition-delay: 0.25s;
  }
  .bi:nth-child(7) {
    transition-delay: 0.3s;
  }
  .bi:nth-child(8) {
    transition-delay: 0.35s;
  }
  .bi:nth-child(9) {
    transition-delay: 0.4s;
  }
  .bi:nth-child(10) {
    transition-delay: 0.45s;
  }
  &:hover {
    .bi {
      font-weight: 700;
    }
    .bi:nth-child(10) {
      transition-delay: 0.45s;
    }
    .bi:nth-child(9) {
      transition-delay: 0.4s;
    }
    .bi:nth-child(8) {
      transition-delay: 0.35s;
    }
    .bi:nth-child(7) {
      transition-delay: 0.3s;
    }
    .bi:nth-child(6) {
      transition-delay: 0.25s;
    }
    .bi:nth-child(5) {
      transition-delay: 0.2s;
    }
    .bi:nth-child(4) {
      transition-delay: 0.15s;
    }
    .bi:nth-child(3) {
      transition-delay: 0.1s;
    }
    .bi:nth-child(2) {
      transition-delay: 0.05s;
    }
    .bi:nth-child(1) {
      transition-delay: 0s;
    }
  }
}

// BUTTON SHINE EFFECT

.btn-shine {
  color: #fff;
  border: none;
}

.btn-shine {
  text-decoration: none;
  position: relative;
  overflow: hidden;
}
.btn-shine:hover {
  background-color: #212529;
  box-shadow: 1px 1px 20px 10px rgba(152, 214, 211, 0.5);
}

.btn-shine:before {
  content: "";
  position: absolute;
  top: 0;
  left: -110%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(152, 214, 211, 0.5),
    transparent
  );
  transition: all 600ms;
}
.btn-shine:hover:before {
  left: 110%;
}
