/*===========================================================

   Template Name: DopeCode - Personal Portfolio React
   File Description : Main css file
	
=================================================
  Table of Contents
=================================================

	1. Basic
	2. Helpers Classes
	3. Layouts
	4. Header
		4.1 Navigation
		4.2 Secondary Nav
		4.3 Page Header
	5 Elements
		5.1 Featured Box
		5.2 Team
		5.3 Accordion
		5.4 Nav
		5.5 Tabs
		5.6 Hero Background
		5.7 Slick Slider
		5.8 Brands Grid
		5.9 Portfolio
		5.10 List Style
	6 Footer
		6.1 Social Icons
		6.2 Back to Top
	7 Extra

=======================================================*/

//-------------------- Fonts --------------------//

$body-font: "Poppins", sans-serif;
$title-font: "Poppins", sans-serif;

//-------------------- Base Colors --------------------//

$complementary-color: #d6989bcc; //complementary color #d6989b
$triadic-second-color: #d6d398; // triadic color
$triadic-first-color: #d398d6; // triadic color
$compound-color: #D69F83; // square color
$compound-color: #DB7C65; // compound color

$primary-color: #98d6d3;
$primary-color-hover: darken($primary-color, 25%);
$secondary-color: #bbbec2;
$body-bg: #dddddd;

//-------------------- Social Icons Colors --------------------//

$facebook: #3b5998;
$twitter: #00acee;
$google: #dd4b39;
$linkedin: #0e76a8;
$youtube: #c4302b;
$instagram: #3f729b;
$dribbble: #ea4c89;
$github: #333333;
$behance: #053eff;
$reddit: #c6c6c6;
$pinterest: #cc2127;
$vk: #2b587a;
$rss: #ee802f;
$skype: #00aff0;
$xing: #126567;
$tumblr: #34526f;
$email: #6567a5;
$delicious: #205cc0;
$stumbleupon: #f74425;
$digg: #191919;
$blogger: #fc4f08;
$flickr: #ff0084;
$vimeo: #86c9ef;
$yahoo: #720e9e;
$appstore: #000;
